<template>
  <div class="memo-interaction" :class="{ 'memo-interaction--end': success }">
    <div class="memo-interaction__container">
      <h2 v-if="!success">Impress Memo</h2>
      <h3 v-if="!success">Find the fitting pairs.</h3>
      <div class="memo-interaction__cards-container" ref="container" v-if="!success">
        <MemoCard v-for="(item, index) in items" :key="'memo-item'+index" :id="item" :asset="assets[parseInt(item)-1]" ref="cards" :disabled="validationTimer"
          :index="index" @flip="onFlip" @unflip="onUnFlip" />
      </div>

      <Button class="button--small" @click.native="onShuffleClick" v-if="!success">SHUFFLE</Button>

      <div class="memo-interaction__end" v-if="success">
        <h2>Congratulations, well done!</h2>
        <h3>Looks like you had fun letting your childhood and mature sides play together. You only got {{flipCount}} attempts in
          total, revealing a wrong pair only {{failCount}} times. All in all, it took you {{minutes}} minutes and {{seconds}} seconds to do the
          whole memo.<br><br>

          That's something to be proud of! If you're feeling ambitious, just try again. Otherwise, you can continue your
          journey through UCHRONIA quite relaxed with this stage success.</h3>
        <Button class="button--small" @click.native="onShuffleClick">TRY AGAIN</Button>
      </div>

      <Button class="button--small" @click.native="onSkipClick" v-if="debug">SKIP</Button>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Button from "../../../components/form/Button";
import MemoCard from "./MemoCard";

export default {
  name: "MemoInteraction",
  mixins: [],
  components: { Button, MemoCard },
  data() {
    return {
      debug:false,
      items: ['1', '2', '3', '4', '5', '6', '6_', '5_', '4_', '3_', '2_', '1_'],
      assets:['auto','camera','computer','waschmachine','zug','telefon'],
      flipped: [],
      validationTimer: undefined,
      flipCount: 0,
      failCount: 0,
      solvedCount: 0,
      startTime: undefined,
      isShuffeling: false,
      success: false,
      minutes: undefined,
      seconds: undefined
    };
  },
  methods: {
    onUnFlip(item) {
      this.flipped = this.flipped.filter((ele) => {
        return ele.id != item.id;
      });
    },
    onFlip(id) {
      clearTimeout(this.validationTimer);
      this.validationTimer = undefined;

      this.flipped.push(id);

      if (this.flipCount === 0) this.startTime = new Date();

      if (this.flipped.length === 2) {
        this.flipCount++;

        this.validationTimer = setTimeout(() => {
          if (this.flipped[0].id === this.flipped[1].id && this.flipped[0].index !== this.flipped[1].index) {
            //TREFFER
            this.$refs.cards[this.flipped[0].index].solve();
            this.$refs.cards[this.flipped[1].index].solve();

            this.solvedCount++;
          } else {
            //console.log("NOPE.");
            this.failCount++;
          }
         //console.log("MATCH");
          this.clearCards();
          this.validationTimer = undefined;
          this.flipped = [];
        }, 500)
      }
    },
    shuffle(array) {
      array.sort(() => Math.random() - 0.5);
    },
    clearCards(force) {
      this.$refs.cards.forEach(card => {
        card.clear(force !== undefined);
      });
    },
    async reset() {
      //console.log("RESET");
      this.flipCount = 0;
      this.solvedCount = 0;
      this.failCount = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.validationTimer = undefined;
      this.flipped = [];

      await this.$nextTick();

       
       this.$refs.cards.forEach(card => {
        card.updateAsset();
        //card.update();
      });
    },
    onComplete() {
      //console.log("COMPLETE!");
      //console.log(this.flipCount + " VERSUCHE GEBRAUCHT.");

      //console.log("STOPTIME:", (new Date().getTime() - this.startTime.getTime()) / 1000)
      this.minutes = 100;
      this.seconds = 100;

      const time = (new Date().getTime() - this.startTime.getTime()) / 1000;

      var minutes = Math.floor(time / 60);
      var seconds = time - minutes * 60;

      this.minutes = Math.round(minutes);
      this.seconds = Math.round(seconds);

      this.success = true;
      //this.onShuffleClick(undefined,.5);
    },
    onSkipClick() {
      //flipCount,
      this.flipCount = 10;
      this.failCount = 2;
      this.seconds = 10;
      this.minutes = 5;

      this.success = true;

    },
    onShuffleClick(e, delay, init) {
      let skipCenter = false;

      if(this.success) {
        init = true;
        skipCenter = true;
      }

      this.success = false;

      this.$nextTick(() => {

        this.isShuffeling = true;
        const parent = this.$refs.container.getBoundingClientRect();
        const centerX = parent.width * .5;
        const centerY = parent.height * .5;

        if (this.solvedCount > 0 || this.flipped.length > 0) delay = .5;
        if (delay === undefined) delay = 0;

        this.$refs.cards.forEach(card => {
          const bounds = card.$el.getBoundingClientRect();
          const percentX = 1 - (bounds.x - parent.x) / centerX;
          const percentY = 1 - (bounds.y - parent.y) / centerY;

          const randomOffsetX = Math.random() * bounds.width * .5 * this.getRandom();
          const randomOffsetY = Math.random() * bounds.width * .5 * this.getRandom();
          const randomRotation = -15 + 30 * this.getRandom();

          let secondDelay = .25 * Math.random() + delay;

          let duration = .5 + Math.random() * .2;
          if (init) {
            //duration = .;
            secondDelay = 0;
          }

          if(skipCenter) duration = 0;

          this.validationTimer = true;

          gsap.to(card.$el, { delay: secondDelay, duration: duration, overwrite: true, ease: "Expo.easeOut", rotation: randomRotation, y: parent.height * .5 * (percentY) - bounds.height * .5 + randomOffsetY, x: parent.width * .5 * (percentX) - bounds.width * .5 + randomOffsetX });
          gsap.to(card.$el, { ease: "Expo.easeOut", duration: .5, rotation: 0, y: 0, x: 0, delay: .25 + duration + secondDelay + Math.random() * .1 });
        })

        this.clearCards(true);

        setTimeout(() => {
          
          this.shuffle(this.items);
          this.reset();
        }, delay * 1000)
      });
    },
    getRandom() {
      const random = Math.random();
      let prefix = 1;

      if (random > .5) prefix = -1;

      return Math.random() * prefix;

    },
    onResize() { },
    init() {
      this.onResize();
    }
  },
  watch: {
    solvedCount() {
      if (this.solvedCount === 6) {
        this.onComplete();
      }
    },
    stageWidth() {
      this.onResize();
    },
    success(){
      this.$nextTick(()=>{

        gsap.set(this.$el,{autoAlpha:0});
        gsap.to(this.$el,{scale:1,autoAlpha:1,delay:.1,overwrite:true,clearProps:'all'});
      });
    },
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() { },

  mounted() {
    this.$nextTick(() => {
      //this.onShuffleClick(undefined,0,true);
    });
  }
};
</script>