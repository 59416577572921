<template>
    <div class="memo-interaction__card">
        <div class="memo-interaction__card-content" :class="{ 'disabled': disabled }" @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave" @click="onClick">
            <div class="memo-interaction__card-raw" ref="card">
                <div class="card__front"><img draggable="false" src="assets/img/Card@2x.gif" alt=""> 
                    <!--<span>{{ id }}</span>-->
                
                </div>
                <div class="card__back">
                    <img draggable="false" :src="'assets/img/memo/' + assetName" alt="">
                </div>
            </div>
            <div class="memo-interaction__card-shadow"></div>

        </div>
    </div>
</template>

<script>
import gsap from "gsap";


export default {
    name: "MemoInteraction",
    mixins: [],
    components: {},
    props: {
        id: undefined,
        disabled: undefined,
        index: undefined,
        asset: undefined
    },

    data() {
        return {
            revealed: false,
            solved: false,
            assetName: undefined
        };
    },

    methods: {
        updateAsset() {
            let asset = this.asset + '.jpg'
            if (this.id.indexOf("_") !== -1) asset = this.asset + "_alt.jpg";
            
            console.log(this.id)
            this.assetName = asset;
        },
        solve() {
            this.solved = true;
            gsap.to(this.$refs.card, { overwrite: true, ease: "Expo.easeOut", force3D: true, duration: 1, rotationY: -180, rotation: this.getRandom() * 2, z: 0 })
            gsap.set(this.$el, { css: { zIndex: '' } });
        },
        clear(force) {
            if (!this.solved || force) {
                setTimeout(() => { this.revealed = false, this.solved = false }, Math.random() * 250);
            }
        },

        onClick() {
            this.revealed = true;//!this.revealed;
        },
        onMouseLeave() {
            if (this.revealed) gsap.set(this.$el, { css: { zIndex: 10 } });
        },
        onMouseEnter() {
            if (this.revealed) gsap.set(this.$el, { css: { zIndex: 11 } });
        },
        getRandom() {
            const random = Math.random();
            let prefix = 1;

            if (random > .5) prefix = -1;

            return Math.random() * prefix;

        },
        onResize() { },
        flip() {
            gsap.set(this.$el, { css: { zIndex: 11 } });
            gsap.to(this.$refs.card, { overwrite: true, ease: "Expo.easeOut", force3D: true, duration: 1, rotationY: -180, rotation: 0, z: 200 })

            gsap.to(this.$el, 1, { duration: 1, ease: "Expo.easeOut", css: { "filter": "drop-shadow(rgba(0,0,0,0.25) 0px 0px 20px)" } });

            this.$emit('flip', { id: parseInt(this.id), index: this.index });
        },
        unFlip() {

            //gsap.set(this.$el, { css: { zIndex: 10 } });
            gsap.set(this.$el, { css: { zIndex: '' } });
            gsap.to(this.$refs.card, { autoAlpha: 1, overwrite: true, ease: "Expo.easeOut", force3D: true, duration: .5, z: 0, rotationY: 0, rotation: this.getRandom() * 2 })

            gsap.to(this.$el, 1, { duration: .5, ease: "Expo.easeOut", css: { "filter": "drop-shadow(rgba(0,0,0,0.3) 0px 0px 5px)" } });

            this.$emit('unflip', { id: parseInt(this.id), index: this.index });
        },
    },
    watch: {
        revealed() {
            if (this.revealed) {
                this.flip();
            } else {
                this.unFlip();
            }
        }

    },
    computed: {


    },
    beforeDestroy() { },

    mounted() {
        gsap.set(this.$refs.card, { force3D: true, autoAlpha: 0, rotation: this.getRandom() * 3, z: 250 });
        this.updateAsset();

        setTimeout(this.unFlip, Math.random() * 150);
    }
};
</script>

<style scoped lang="scss">
</style>